import React, {useEffect, useState} from "react";
import Tree from "rc-tree";
import 'rc-tree/assets/index.css'
import '../groups/draggable.css'
import {Business, CreditCard, ExpandLess, ExpandMore, LocalBar, Save, Store, Storefront} from "@mui/icons-material";
import {
    Create,
    FormDataConsumer,
    SimpleForm,
    useDataProvider,
    useNotify,
    useStore,
    useTranslate
} from "react-admin";
import {
    Button,
    Checkbox,
    Dialog,
    DialogContent,
    DialogTitle,
    FormControl,
    FormControlLabel,
    FormGroup,
    FormHelperText,
    FormLabel, IconButton,
    Input,
    LinearProgress,
    Radio,
    RadioGroup,
    Typography
} from "@mui/material";
import {PinkToolbar} from "../../components/PinkToolbar";
import {traverse_tree} from "../../tools/groupTransform";
import CloseIcon from '@mui/icons-material/Close';
import UnfoldMoreDoubleIcon from '@mui/icons-material/UnfoldMoreDouble';
import UnfoldLessDoubleIcon from '@mui/icons-material/UnfoldLessDouble';
import Autocomplete from "@mui/material/Autocomplete";
import {useNavigate} from "react-router-dom";
import RedirectToPurchase from "../../components/RedirectToPurchase";
import MuiTextField from "@mui/material/TextField";
import Tooltip from "@mui/material/Tooltip";
import HelpIcon from "@mui/icons-material/Help";


const SwitcherIcon = (obj) => {
    if (obj.isLeaf) {
        return (<div/>)
    }
    if (obj.expanded) {
        return (<div style={{backgroundColor: "#fafafb"}}><ExpandLess/></div>)
    } else {
        return (<div style={{backgroundColor: "#fafafb"}}><ExpandMore/></div>)
    }
}

const Icon = (obj) => {
    if (obj.data.level) {
        switch (obj.data.level) {
            case 1:
                return (<div><CreditCard/></div>)
            case 2:
                return (<LocalBar/>)
            case 3:
                return (<Storefront/>)
            case 4:
                return (<Store/>)
            case 5:
                return (<Business/>)
            default:
                break;
        }
    } else {
        return <div/>
    }
}


const ConfigToPosSet = () => {
    const notify = useNotify()
    const translate = useTranslate()
    const dataProvider = useDataProvider()
    const [data, setData] = useState({loading: false, loaded: false, data: [], error: undefined});
    const [configData, setConfigData] = useState({loading: false, loaded: false, data: [], error: undefined});
    const [paymentChoices, setPaymentChoices] = useState({loading: false, loaded: false, data: [], error: undefined});
    const [gData, setGData] = useState([])
    const [autoExpandParent, setAutoExpandParent] = useState(true)
    const [expandedKeys, setExpandedKeys] = useStore('configToPosSetexpKeys', [])
    const [dialogOpen, setDialogOpen] = useState(false)
    const [editNode, setEditNode] = useState({})
    const [expandableIds, setExpandableIds] = useState([])
    const [expanded, setExpanded] = useState(false)
    const [isShowRedirect, setIsShowRedirect] = useState(false)
    const [companies, setCompanies] = useState([])
    const [selectedCompany, setSelectedCompany] = useState(null)
    const navigate = useNavigate()

    useEffect(() => {
        dataProvider.get(`config/companies`)
            .then(value => {
                if (value.data.list.length === 0) {
                    navigate("/license")
                } else {
                    setCompanies(value.data.list)
                    let selectedPosSetId
                    if (value.data.defaultPosSetId !== null) {
                        selectedPosSetId = parseInt(value.data.defaultPosSetId)
                    }
                    if (value.data.list.map(it => it?.id).includes(parseInt(selectedPosSetId))) {
                        setSelectedCompany(value.data.list.find(obj => obj?.id === selectedPosSetId))
                        getPosConfigTree(selectedPosSetId)
                    } else {
                        setSelectedCompany(value.data.list[0])
                        getPosConfigTree(value.data.list[0]?.id)
                    }
                }
            })
            .catch(reason => {
                setCompanies(null)
            })
    }, [])

    useEffect(() => {
        if (selectedCompany !== null) {
            getPosConfigTree(selectedCompany?.id)
            setExpanded(false)
            setExpandedKeys([])
            selectedCompany.posSetSelectorPrivileges.getPrivilegeCompany ? setIsShowRedirect(false) : setIsShowRedirect(true)
        }
    }, [selectedCompany]);

    useEffect(() => {
        if (!paymentChoices.loading && !paymentChoices.loaded) {
            setPaymentChoices({...paymentChoices, loading: true});
            dataProvider.get('config/paymentMethods')
                .then(value => {
                    setPaymentChoices({loading: false, loaded: true, data: value.data, error: undefined});
                })
                .catch(reason => {
                    setPaymentChoices({loading: false, loaded: true, data: undefined, error: reason});
                })
        }
    }, [paymentChoices.loading, paymentChoices.loaded]);

    const sortTreeData = (data) => {
        return data
            .filter(item => item.level !== 7)
            .sort((a, b) => a.title.localeCompare(b.title))
            .map(item => {
                if (item.children) {
                    item.children = sortTreeData(item.children);
                }
                return item;
            });
    };


    const getPosConfigTree = (id) => {
        setExpanded(false)
        dataProvider.get(`config/hierarchy`, {companyId: id})
            .then(value => {
                const sortedData = sortTreeData(value.data);
                setData({loading: false, loaded: true, data: sortedData, error: undefined});
                traverse_tree(sortedData, setExpandableIds);
                Array.isArray(sortedData) ? setGData(sortedData) : setGData([sortedData]);
            })
            .catch(reason => {
                setData({loading: false, loaded: true, data: undefined, error: reason});
            })
    }

    const handleDialogClose = () => {
        setDialogOpen(false)
        setConfigData({...configData, data: [], loading: false, loaded: false})
    }

    const handleExpandAll = () => {
        setExpanded(true)
        setExpandedKeys(expandableIds)
    };

    const handleCollapseAll = () => {
        setExpanded(false)
        setExpandedKeys([])
    };

    const onExpand = (expandedKeys) => {
        if (expandedKeys.length === expandableIds.length) {
            setExpanded(true)
        }

        if (expandedKeys.length === 0) {
            setExpanded(false)
        }

        setExpandedKeys(expandedKeys)
        setAutoExpandParent(false)
    };

    const getFormData = eId => {
        if (!configData.loading && !configData.loaded) {
            setConfigData({...data, loading: true});
            dataProvider.get('config', {id: eId})
                .then(value => {
                    value.data.paymentMethods = value.data.paymentMethods.filter(e => (e !== "HELLO_PAY" && e !== "MIXED"))
                    setConfigData({loading: false, loaded: true, data: value.data, error: undefined});
                })
                .catch(reason => {
                    setConfigData({loading: false, loaded: true, data: undefined, error: reason});
                })
        }
    }

    const onSelect = (selectedKeys, e) => {
        setEditNode(e.node)
        setDialogOpen(true)
        getFormData(e.node.id)
    }

    const handleEditNode = (e) => {
        //to fix double click error
        if (e.detail === 1) {
            handleDialogClose()
            dataProvider.create('config', {
                data: {
                    posSetId: editNode.id,
                    paymentMethods: configData.data.paymentMethods,
                    paymentDevice: configData.data.paymentDevice,
                    clientDisplay: configData.data.clientDisplay,
                    secondScreenVideo: configData.data.secondScreenVideo,
                    hideItemsFromCustomer: configData.data.hideItemsFromCustomer,
                    taxPrinter: configData.data.taxPrinter,
                    cashRegisterConnected: configData.data.cashRegisterConnected,
                    serviceFeePercent: configData.data.serviceFeePercent,
                    discountAllowed: configData.data.discountAllowed,
                    maxDiscountPercent: configData.data.maxDiscountPercent,
                    tableManagement: configData.data.tableManagement,
                    tipVatBreakdown: configData.data.tipVatBreakdown,
                    tipVatBreakdownPercent: configData.data.tipVatBreakdownPercent,
                    tipVatBreakdownCard: configData.data.tipVatBreakdownCard,
                    tipVatBreakdownPercentCard: configData.data.tipVatBreakdownPercentCard,
                    staffDiscountAllowed: configData.data.staffDiscountAllowed,
                    maxStaffDiscountPercent: configData.data.maxStaffDiscountPercent,
                    donationAllowed: configData.data.donationAllowed,
                    donationPercent: configData.data.donationPercent
                }
            })
                .then(value => {
                    notify("config.updated", {type: "success"})
                    getPosConfigTree(selectedCompany.id)
                })
                .catch(reason => {
                    console.log(reason)
                    notify("config.error", {type: "error"})
                })
            setConfigData({...configData, data: [], loading: false, loaded: false})

        }
    }

    const handlePaymentMethodChange = (event) => {
        const {name, checked} = event.target;
        let payMethods = [...configData.data.paymentMethods];

        if (checked) {
            payMethods.push(name);

            if (name === 'CARD' && !payMethods.includes('CARD_MANUAL')) {
                payMethods.push('CARD_MANUAL');
            }
        } else {
            payMethods = payMethods.filter(e => e !== name);
        }

        const config = {
            ...configData.data,
            paymentMethods: payMethods,
            paymentDevice: (name === 'CARD' && !checked) ? 'NONE' : configData.data.paymentDevice
        };

        setConfigData({...configData, data: config});
    };

    const handleRadioButtonChange = (event) => {
        let config = configData.data
        config[event.target.name] = event.target.value
        if (event.target.value === 'NONE' && event.target.name === 'paymentDevice') {
            config.paymentMethods = config.paymentMethods.filter(item => item !== 'CARD' && item !== 'CARD_MANUAL')
            setConfigData({...configData, data: config})
        } else if (event.target.value === 'NOT_INTEGRATED_TERMINAL') {
            config.paymentMethods = config.paymentMethods.filter(item => item !== 'CARD')
            setConfigData({...configData, data: config})
        } else {
            setConfigData({...configData, data: config})
        }
    }

    const handleBooleanFieldChange = (event) => {
        let config = configData.data
        if (event.target.name === 'taxPrinter' && event.target.checked) {
            config['cashRegisterConnected'] = false
        }
        if (event.target.name === 'cashRegisterConnected' && event.target.checked) {
            config['taxPrinter'] = false
        }
        config[event.target.name] = event.target.checked
        setConfigData({...configData, data: config})
    }

    const handleNumberInputChange = (event) => {
        let config = configData.data
        if (isNaN(parseInt(event.target.value))) {
            event.target.value = 0
        }
        if (parseInt(event.target.value) > 100) {
            event.target.value = 100
        }
        if (parseInt(event.target.value) < 0) {
            event.target.value = 0
        }
        config[event.target.id] = parseInt(event.target.value)
        setConfigData({...configData, data: config})
    }

    return (
        <div className="draggable-demo">

            <Dialog open={dialogOpen} fullWidth={true} maxWidth={"xs"} onClose={handleDialogClose}>
                <div className={"groupDialog"}>
                    <DialogTitle id="alert-dialog-title">
                        {editNode.name} - {translate('config.config')}
                    </DialogTitle>
                    <DialogContent>
                        {configData.data.paymentMethods !== undefined &&
                            <div style={{display: "flex", flexDirection: "column"}}>
                                <FormControl
                                    required
                                    error={configData.data.paymentMethods.length === 0}
                                >
                                    <FormLabel>{translate("config.paymentMethods")}</FormLabel>
                                    <FormGroup>
                                        {paymentChoices.data.filter(e => e !== "VAT_INVOICE_CASH" && e !== "VAT_INVOICE_CARD" && e !== "SZEP_CARD_MANUAL")
                                            .map(e => {
                                            if (e !== "HELLO_PAY" && e !== "MIXED") {
                                                return <FormControlLabel control={
                                                    <Checkbox
                                                        checked={configData.data.paymentMethods.some(x => x === e)}
                                                        onChange={handlePaymentMethodChange}
                                                        name={e}
                                                        disabled={e === "CARD_MANUAL" && configData.data.paymentMethods.some(x => x === "CARD") || (configData.data.paymentDevice === "SOFT_POS" && (e === "HELLO_PAY_VIP" || e === "SZEP_CARD_MANUAL" || e === "SZEP_CARD_TERMINAL" || e === "VOUCHER"))}
                                                    />
                                                }
                                                                         label={translate("config." + e)}
                                                                         key={e}/>
                                            }
                                            return null
                                        })}
                                    </FormGroup>
                                    <FormHelperText
                                        style={{fontSize: "17px"}}>{translate("config.pickMinimumOne")}</FormHelperText>
                                </FormControl>
                                <FormLabel style={{marginTop: "20px"}}
                                           id="payment-device-radio-buttons-group-label">{translate("config.paymentDevice")}</FormLabel>
                                <RadioGroup
                                    aria-labelledby="payment-device-radio-buttons-group-label"
                                    value={configData.data.paymentDevice}
                                    name="paymentDevice"
                                    onChange={handleRadioButtonChange}
                                >
                                    <FormControlLabel value="NONE" control={<Radio/>} label={translate("config.none")}/>
                                    <FormControlLabel value="HELLO_PAY_TERMINAL" control={<Radio/>}
                                                      label={translate("config.helloPayTerminal")}/>
                                    <FormControlLabel value="SOFT_POS" control={<Radio/>}
                                                      label={translate("config.soft_pos")}/>
                                    <FormControlLabel value="NOT_INTEGRATED_TERMINAL" control={<Radio/>}
                                                      label={translate("config.notIntegrated")}/>
                                </RadioGroup>

                                <FormLabel style={{marginTop: "20px"}}
                                           id="client-display-radio-buttons-group-label">{translate("config.clientDisplay")}</FormLabel>
                                <RadioGroup
                                    aria-labelledby="client-display-radio-buttons-group-label"
                                    value={configData.data.clientDisplay}
                                    name="clientDisplay"
                                    onChange={handleRadioButtonChange}
                                >
                                    <FormControlLabel value="NONE" control={<Radio/>} label={translate("config.none")}/>
                                    <FormControlLabel value="HELLO_PAY_CD" control={<Radio/>}
                                                      label={translate("config.helloPayCD")}/>
                                    <FormControlLabel value="INTEGRATED_CD" control={<Radio/>}
                                                      label={translate("config.integratedCD")}/>
                                </RadioGroup>

                                <FormControlLabel
                                    control={<Checkbox checked={configData.data.secondScreenVideo}
                                                       onChange={handleBooleanFieldChange} name={"secondScreenVideo"}/>}
                                    label={translate("config.secondScreenVideo")}
                                />

                                <FormControlLabel
                                    control={<Checkbox checked={configData.data.hideItemsFromCustomer}
                                                       onChange={handleBooleanFieldChange}
                                                       name={"hideItemsFromCustomer"}/>}
                                    label={translate("config.hideItemsFromCustomer")}
                                />

                                <FormLabel style={{marginTop: "20px"}}
                                           id="tax-printer-buttons-group-label">{translate("config.taxPrinter")}</FormLabel>

                                <FormControlLabel
                                    control={<Checkbox checked={configData.data.taxPrinter}
                                                       onChange={handleBooleanFieldChange} name={"taxPrinter"}/>}
                                    label={translate("config.taxPrinterConnected")}
                                />
                                <FormControlLabel
                                    control={<Checkbox checked={configData.data.cashRegisterConnected}
                                                       onChange={handleBooleanFieldChange}
                                                       name={"cashRegisterConnected"}/>}
                                    label={translate("config.cashRegisterConnected")}
                                />
                                <FormLabel style={{marginTop: "20px"}}
                                           id="table-allowed-radio-buttons-group-label">{translate("config.tableManagement")}</FormLabel>
                                <FormControlLabel control={
                                    <Checkbox
                                        checked={configData.data.tableManagement}
                                        onChange={handleBooleanFieldChange}
                                        name={"tableManagement"}
                                    />}
                                                  label={translate("config.tableManagement")}
                                                  key={"tableManagement"}
                                />
                                <FormLabel style={{marginTop: "20px"}}
                                           id="tip-display-radio-buttons-group-label">{translate("config.clientTip")}</FormLabel>
                                <RadioGroup
                                    aria-labelledby="tip-display-radio-buttons-group-label"
                                    value={configData.data.tipVatBreakdown}
                                    name="tipVatBreakdown"
                                    onChange={handleRadioButtonChange}
                                >
                                    <FormControlLabel value="ON_RECEIPT" control={<Radio/>}
                                                      label={translate("config.onReceipt")}/>
                                    <FormControlLabel value="DIRECT_TIP" control={<Radio/>}
                                                      label={translate("config.directTip")}/>
                                    <FormControlLabel value="INCOME_RECEIPT_PRINT" control={<Radio/>}
                                                      label={translate("config.incomeReceiptPrint")}/>
                                    <FormControlLabel value="MIXED_BREAKDOWN" control={<Radio/>}
                                                      label={translate("config.mixedBreakdown")}/>
                                </RadioGroup>

                                {configData.data.tipVatBreakdown === "MIXED_BREAKDOWN" &&
                                    <>
                                        <FormLabel style={{marginTop: "20px"}}
                                                   id="tipVatBreakdownPercent">{translate("config.mixedField")}</FormLabel>
                                        <div style={{display: 'flex', justifyContent: 'space-between'}}>
                                        <Input id="tipVatBreakdownPercent"
                                               value={configData.data.tipVatBreakdownPercent}
                                               onChange={handleNumberInputChange}
                                               sx={{width: "100%"}}
                                        />
                                            <Tooltip title={translate("config.mixedFieldTooltip")}>
                                                <IconButton>
                                                    <HelpIcon sx={{color: "#ff71bc"}}/>
                                                </IconButton>
                                            </Tooltip>
                                        </div>
                                    </>}

                                <FormLabel style={{marginTop: "20px"}}
                                           id="tipCard-display-radio-buttons-group-label">{translate("config.clientTipCard")}</FormLabel>
                                <RadioGroup
                                    aria-labelledby="tipCard-display-radio-buttons-group-label"
                                    value={configData.data.tipVatBreakdownCard}
                                    name="tipVatBreakdownCard"
                                    onChange={handleRadioButtonChange}
                                >
                                    <FormControlLabel value="ON_RECEIPT" control={<Radio/>}
                                                      label={translate("config.onReceipt")}/>
                                    <FormControlLabel value="DIRECT_TIP" control={<Radio/>}
                                                      label={translate("config.directTip")}/>
                                    <FormControlLabel value="INCOME_RECEIPT_PRINT" control={<Radio/>}
                                                      label={translate("config.incomeReceiptPrint")}/>
                                    <FormControlLabel value="MIXED_BREAKDOWN" control={<Radio/>}
                                                      label={translate("config.mixedBreakdown")}/>
                                </RadioGroup>

                                {configData.data.tipVatBreakdownCard === "MIXED_BREAKDOWN" &&
                                    <>
                                        <FormLabel style={{marginTop: "20px"}}
                                                   id="tipVatBreakdownPercentCard">{translate("config.mixedField")}</FormLabel>
                                        <div style={{display: 'flex', justifyContent: 'space-between'}}>
                                        <Input id="tipVatBreakdownPercentCard"
                                               value={configData.data.tipVatBreakdownPercentCard}
                                               onChange={handleNumberInputChange}
                                               sx={{width: "100%"}}
                                        />
                                            <Tooltip title={translate("config.mixedFieldTooltip")}>
                                                <IconButton>
                                                    <HelpIcon sx={{color: "#ff71bc"}}/>
                                                </IconButton>
                                            </Tooltip>
                                        </div>
                                    </>
                                }

                                <FormLabel style={{marginTop: "20px"}}
                                           id="serviceFeePercent-radio-buttons-group-label">{translate("config.serviceFeePercent")}</FormLabel>
                                <Input id="serviceFeePercent"
                                       value={configData.data.serviceFeePercent}
                                       onChange={handleNumberInputChange}
                                />

                                <FormLabel style={{marginTop: "20px"}}
                                           id="discount-allowed-radio-buttons-group-label">{translate("config.discount")}</FormLabel>
                                <FormControlLabel control={
                                    <Checkbox
                                        checked={configData.data.discountAllowed}
                                        onChange={handleBooleanFieldChange}
                                        name={"discountAllowed"}
                                    />}
                                                  label={translate("config.discountAllowed")}
                                                  key={"discountAllowed"}
                                />

                                <FormLabel style={{marginTop: "20px"}}
                                           id="maxDiscountPercent-radio-buttons-group-label">{translate("config.maxDiscountPercent")}</FormLabel>
                                <Input id="maxDiscountPercent"
                                       value={configData.data.maxDiscountPercent}
                                       onChange={handleNumberInputChange}
                                />

                                <FormLabel style={{marginTop: "20px"}}
                                           id="staff-consumption">{translate("config.STAFF_CONSUMPTION")}
                                    <Tooltip sx={{marginLeft: '5px'}} title={translate("config.staffTooltip")} placement={"right"}>
                                        <IconButton>
                                            <HelpIcon sx={{color: "#ff71bc"}}/>
                                        </IconButton>
                                    </Tooltip>
                                </FormLabel>
                                <FormControlLabel control={
                                    <Checkbox
                                        checked={configData.data.staffDiscountAllowed}
                                        onChange={handleBooleanFieldChange}
                                        name={"staffDiscountAllowed"}
                                    />}
                                                  label={translate("config.staffAllowed")}
                                                  key={"staffDiscountAllowed"}
                                />

                                <FormLabel style={{marginTop: "20px"}}
                                           id="maxStaffDiscountPercent-radio-buttons-group-label">{translate("config.maxStaffDiscountPercent")}</FormLabel>
                                <Input id="maxStaffDiscountPercent"
                                       value={configData.data.maxStaffDiscountPercent}
                                       onChange={handleNumberInputChange}
                                />

                                {/*innen*/}
                                <FormLabel style={{marginTop: "20px"}}
                                           id="donation">{translate("config.donation")}
                                    <Tooltip sx={{marginLeft: '5px'}} title={translate("config.donationTooltip")} placement={"right"}>
                                        <IconButton>
                                            <HelpIcon sx={{color: "#ff71bc"}}/>
                                        </IconButton>
                                    </Tooltip>
                                </FormLabel>
                                <FormControlLabel control={
                                    <Checkbox
                                        checked={configData.data.donationAllowed}
                                        onChange={handleBooleanFieldChange}
                                        name={"donationAllowed"}
                                    />}
                                                  label={translate("config.donationAllowed")}
                                                  key={"donationAllowed"}
                                />

                                <FormLabel style={{marginTop: "20px"}}
                                           id="maxdonationPercent-radio-buttons-group-label">{translate("config.donationPercent")}</FormLabel>
                                <Input id="donationPercent"
                                       value={configData.data.donationPercent}
                                       onChange={handleNumberInputChange}
                                />

                            </div>}
                        <FormDataConsumer>
                            {({formData, ...rest}) =>
                                <div className="editNodeToolbar" style={{marginTop: "20px"}}>
                                    <Button style={{marginTop: "8px", marginRight: "8px", color: "white"}}
                                            variant={"contained"}
                                            color={"primary"}
                                            onClick={event => handleEditNode(event, formData)}
                                            disabled={configData.data.paymentMethods?.length === 0}
                                    >
                                        <Save/>{translate("ra.action.save")}
                                    </Button>

                                    <Button style={{marginTop: "8px", marginRight: "8px"}} variant={"outlined"}
                                            color={"error"}
                                            onClick={event => handleDialogClose(event, formData)}
                                    >
                                        <CloseIcon/>{translate("ra.action.close")}
                                    </Button>

                                </div>
                            }
                        </FormDataConsumer>
                    </DialogContent>
                </div>
            </Dialog>
            <Typography style={{marginRight: "8px", marginBottom: "10px"}}
                        variant={"h4"}>{translate("config.title")} </Typography>

            <Autocomplete
                options={companies || null}
                getOptionLabel={option => option.name || ""}
                onChange={(event, value) => {
                    setSelectedCompany(value)
                }}
                value={selectedCompany}
                defaultValue={selectedCompany}
                renderInput={params => (
                    <MuiTextField
                        {...params}
                        variant="standard"
                        label={translate("companySelector.selectCompany")}
                        fullWidth
                    />
                )}
                style={{width: "300px"}}
                disableClearable
            />

            <div style={{display: "flex", marginTop: "20px", marginBottom: "10px"}}>
                {expanded ?
                    <>
                        <UnfoldLessDoubleIcon style={{color: "#ff71bc"}} onClick={handleCollapseAll}/>
                        <Typography variant={"p"}
                                    style={{verticalAlign: "middle"}}>{translate("groups.collapseAll")} </Typography>
                    </>
                    :
                    <>
                        <UnfoldMoreDoubleIcon style={{color: "#ff71bc"}} onClick={handleExpandAll}/>
                        <Typography variant={"p"}>{translate("groups.expandAll")} </Typography>
                    </>
                }

            </div>
            <div className="draggable-container">
                {gData?.length > 0 ?
                    <Tree
                        treeData={gData}
                        expandedKeys={expandedKeys}
                        onExpand={onExpand}
                        autoExpandParent={autoExpandParent}
                        showLine
                        onSelect={onSelect}
                        icon={Icon}
                        switcherIcon={SwitcherIcon}
                    />
                    : isShowRedirect ?

                        <RedirectToPurchase resource={"config"}/>
                        :
                        <LinearProgress/>
                }
            </div>
        </div>
    );
}

export const SendConfigToPosSet = ({permissions}) => {
    return (
        <Create title={"config.title"} redirect={false}>
            <SimpleForm toolbar={<PinkToolbar noSaveButton={true} deleteEnabled={false}/>}>
                <ConfigToPosSet/>
            </SimpleForm>
        </Create>
    )
}